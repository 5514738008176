import React from 'react'
import PropTypes from 'prop-types'

import {
  Container,
  Directions,
  SocialList,
  ListElement,
  SocialIcon,
  SocialLink,
} from './style'

import { remarkPropType, socialLinkPropType } from '../../types/propTypes'

const getDirections = ({ frontmatter, html }) => (
  <Directions className="col-sm-6 col-md-4">
    <h2>{frontmatter.title}</h2>
    <section dangerouslySetInnerHTML={{ __html: html }} />
  </Directions>
)

getDirections.propTypes = remarkPropType

const generateSocialHandle = ({
  id, logo, handle, link,
}) => (
  <ListElement key={`SocialHandle-${id}`}>
    <SocialIcon type={logo} />
    <SocialLink href={link}>{handle}</SocialLink>
  </ListElement>
)

generateSocialHandle.propTypes = socialLinkPropType

const getSocialList = (socialList) => (
  <SocialList className="col-sm-6 col-md-offset-4 col-md-4">
    {socialList.map(generateSocialHandle)}
  </SocialList>
)

function ContactInfo({ directions, socialList }) {
  return (
    <Container>
      <div className="container">
        <div className="row">
          {getDirections(directions)}
          {getSocialList(socialList)}
        </div>
      </div>
    </Container>
  )
}

ContactInfo.propTypes = {
  directions: PropTypes.object.isRequired,
  socialList: PropTypes.arrayOf(PropTypes.shape(socialLinkPropType)).isRequired,
}

export default ContactInfo
