import styled from '@emotion/styled'
import colors from '../../styles/colors'

export const PrimaryButton = styled.button`
  cursor: pointer;
  border: none;
  border-radius: 6px;
  background: ${colors.primary};
  color: ${colors.inverted.primary};
  margin: 0 0 5px;
  ${(props) => {
    switch (props.padding) {
      case 'large':
        return 'padding: 30px 45px;'
      default:
        return 'padding: 10px 15px;'
    }
  }}
  font-size: 18px;
  font-weight: bold;
`
