import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import Layout from '../layouts/default'
import SEO from '../components/seo'
import ContactInfo from '../components/contactInfo'
import ContactForm from '../components/contactForm'
import { Title } from '../components/common/text'

function ContactUsPage({ data }) {
  return (
    <Layout>
      <SEO title="Contáctanos" />
      <Title>Contáctanos</Title>
      <ContactInfo
        directions={data.directions}
        socialList={data.socialList.nodes}
      />
      <ContactForm email={data.site.siteMetadata.email} />
    </Layout>
  )
}

// TODO: Make this more detailed
ContactUsPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export const query = graphql`
  query ContactUsDataQuery {
    site {
      siteMetadata {
        email
      }
    }

    directions: markdownRemark(frontmatter: { title: { eq: "Dirección" } }) {
      frontmatter {
        title
      }
      html
    }

    socialList: allSocialsYaml {
      nodes {
        id
        logo
        handle
        link
      }
    }
  }
`

export default ContactUsPage
