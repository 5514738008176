import styled from '@emotion/styled'
import SocialIconEl from '../icons/socialIcon'
import { mediaQuery } from '../../styles/base'
import colors from '../../styles/colors'

export const Container = styled.section`
  margin-bottom: 30px;
`

export const Directions = styled.article`
  background-color: ${colors.secondary};
  color: ${colors.inverted.primary};
  padding: 15px;
  margin: 0 auto;

  h1,
  h2 {
    color: ${colors.inverted.primary};
    text-align: center;
  }

  hr {
    background: ${colors.inverted.primary};
  }
`

export const SocialList = styled.ul`
  list-style-type: none;
  ${mediaQuery({
    marginLeft: ['auto', 0, '33.33333333%'],
    marginRight: ['auto', 0],
    marginTop: [30, 0],
    marginBottom: 0,
  })}
`

export const ListElement = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: 30px;
`

export const SocialIcon = styled(SocialIconEl)`
  fill: ${colors.primary};
  height: 40px;
  width: 40px;
  margin-right: 30px;
`

export const SocialLink = styled.a`
  color: ${colors.primary};
`
