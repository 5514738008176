import styled from '@emotion/styled'
import { PrimaryButton } from '../common/buttons'
import { mediaQuery } from '../../styles/base'

export const SubmitContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const SubmitBtn = styled(PrimaryButton)`
  ${mediaQuery({
    width: ['100%', 'auto'],
  })}
`
