import styled from '@emotion/styled'
import colors from '../../styles/colors'

export const Form = styled.form`
  fieldset {
    border: medium none;
    min-width: 100%;
    width: 100%;
    padding: 0;
    margin: 0 0 10px;
  }

  input,
  textarea {
    width: 100%;
    margin: 0 0 5px;
  }

  textarea {
    height: 168px;
    max-width: 100%;
    resize: none;
  }
`

export const FormInput = styled.input`
  border-radius: 3px;
  border: 1px solid ${colors.tertiary};
  background: #ffffff;
  padding: 10px;

  &:hover {
    transition: border-color 0.3s ease-in-out;
    border: 1px solid ${colors.secondary};
  }
`

export const FormTextarea = FormInput.withComponent('textarea')
