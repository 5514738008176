import React from 'react'
import PropTypes from 'prop-types'

import { Form, FormInput, FormTextarea } from '../common/form'
import { SubmitContainer, SubmitBtn } from './style'

function ContactForm({ email }) {
  return (
    <Form
      action={`https://formspree.io/${email}`}
      method="POST"
      className="container"
    >
      <div className="row">
        <div className="col-xs-12 col-sm-6">
          <fieldset>
            <FormInput
              placeholder="Nombre"
              name="first-name"
              type="text"
              tabIndex={-4}
              required
            />
          </fieldset>
          <fieldset>
            <FormInput
              placeholder="Apellido"
              name="last-name"
              type="text"
              tabIndex={-3}
              required
            />
          </fieldset>
          <fieldset>
            <FormInput
              placeholder="Correo"
              name="email"
              type="email"
              tabIndex={-2}
              required
            />
          </fieldset>
        </div>
        <div className="col-xs-12 col-sm-6">
          <fieldset>
            <FormTextarea
              placeholder="¿Con qué te podemos ayudar?"
              name="message"
              tabIndex={-1}
              required
            />
          </fieldset>
          <SubmitContainer>
            <SubmitBtn name="submit" type="submit">
              Enviar
            </SubmitBtn>
          </SubmitContainer>
        </div>
      </div>
    </Form>
  )
}

ContactForm.propTypes = {
  email: PropTypes.string.isRequired,
}

export default ContactForm
